import API_ACTION_TYPES from "./action-types";
import { API_STATES } from "../app-constants";
import {
  SET_SORT_STATE,
  SET_DIALOG_STATE,
  SET_LOGGED_IN_STATUS,
  SET_IS_SUBSCRIBING,
} from "./app-actions";
const api_action_types = API_ACTION_TYPES();
const initialState = {
  urlFetchPrefixes: {
    get: null,
    update: "update-",
    insert: "insert-",
    delete: "delete-",
  },
  api: api_action_types,
  sortState: {},
  dialogs: {},
  data: {},
  isLoggedIn: false,
  isSubscribing: false,
};

export default function app(state = initialState, action) {
  // console.log(action_types);
  // console.log(apiReducerLookup);

  switch (action.type) {
    case SET_SORT_STATE: {
      const { payload } = action;
      return {
        ...state,
        sortState: Object.assign(
          { ...state.sortState },
          { [payload.data_name]: payload }
        ),
      };
    }
    case SET_DIALOG_STATE: {
      const { payload } = action;
      const { dialogName, open, meta } = payload;
      return {
        ...state,
        dialogs: Object.assign(
          { ...state.dialogs },
          { [dialogName]: { status: open, ...meta } }
        ),
      };
    }
    case SET_LOGGED_IN_STATUS: {
      const { payload } = action;

      return {
        ...state,
        ...payload,
      };
    }
    case SET_IS_SUBSCRIBING: {
      const { payload } = action;

      return {
        ...state,
        ...payload,
      };
    }

    default: {
      return state;
    }
  }
}
