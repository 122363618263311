import "react-app-polyfill/stable";
import React from "react";
import "../../assets/scss/master.scss";
import "../../assets/react-draft-wysiwyg.css";
import "../../assets/fonts/fonts.css";
import configureStore, {
    RRF_CONFIG,
    FIREBASE_CONFIG,
} from "../../data/configure-store";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";
import { createFirestoreInstance } from "redux-firestore";
import {Provider} from "react-redux";
import {ReactReduxFirebaseProvider} from "react-redux-firebase";
import {BrowserRouter as Router} from "react-router-dom";
import {ThemeProvider} from "@material-ui/core/styles";
import {fontTheme} from "../../settings";
import BtbApp from "./btb-app";


let store = configureStore();

firebase.initializeApp(FIREBASE_CONFIG);
firebase.firestore();


const rrfProps = {
    firebase: firebase,
    config: RRF_CONFIG,
    dispatch:store.dispatch,
    createFirestoreInstance: createFirestoreInstance,
};


const BtbBaseApp = () => (
    <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
            <Router>
                <ThemeProvider theme={fontTheme}>
                    <BtbApp />
                </ThemeProvider>
            </Router>
        </ReactReduxFirebaseProvider>
    </Provider>
);

export default BtbBaseApp;
