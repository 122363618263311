import { AVR_ENDPOINTS, AVR_API_ACTIONS } from "../app-constants";

const API_ACTION_TYPES = () => {
  let final_dict = {};
  for (const endpoint in AVR_ENDPOINTS) {
    //final array

    const final = AVR_API_ACTIONS.map(
      (item) => `${endpoint.toUpperCase()}_${item}`
    );
    let inner_dict = {};
    for (let i of final) {
      const key = i.split("_")[1].toLowerCase();
      inner_dict[key] = i;
    }

    inner_dict["url"] = AVR_ENDPOINTS[endpoint].url;
    inner_dict["authorization"] = AVR_ENDPOINTS[endpoint].authorization;
    inner_dict["data_name"] = AVR_ENDPOINTS[endpoint].data_name;
    inner_dict["methods"] = AVR_ENDPOINTS[endpoint].methods;

    final_dict[endpoint] = inner_dict;
  }

  return final_dict;
};

export default API_ACTION_TYPES;
