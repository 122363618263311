import {createTheme} from "@material-ui/core/styles";

export const fontTheme = createTheme({
    palette: {
        type: "light",
        common: {
            black: "#000",
            white: "#fff",
            gray0: "#999",
            gray1: "#3C4858",
            gray2: "#eee",
            gray3: "#343434",
            gray5: "#585858",
            gray6: "#232323",
            gray7: "#ddd",
            gray8: "#6c757d",
            gray9: "#333",
            gray10: "#212121",
            gray11: "#777",
            gray12: "#D2D2D2",
            gray13: "#495057",
            gray14: "#e5e5e5",
            gray15: "#555",
            gray16: "#f9f9f9",
            gray17: "#ccc",
            gray18: "#444",
            gray19: "#f2f2f2",
            gray20: "#89229b",
            gray21: "#c0c1c2",
            gray22: "#9a9a9a",
            gray23: "#f5f5f5",
            gray24: "#505050",
            lightGrey: "#fafafa",
            panelGrey: "#f5f5f5",
            grey200: "#eeeeee",
            grey300: "#e0e0e0",
            grey400: "#bdbdbd",
            grey500: "#9e9e9e",
            grey600: "#757575",
            grey700: "#616161",
            grey800: "#424242",
            grey900: "#212121",
            cmbPrimary:"#AF1212",
            logoTurquoise: "#4dd0e1",
            logoRed: "#a21000",
            logolightRose: "#DCA4A3",
            logoPink: "#FE8A9E",
            logoLightPink: "#F5E5E8",
            logoBlue: "#2DA2BF",
            logoOrange: "#EB641B",
            logoIndigo: "#474B78",
            logoDarkRed: "#7D3C4A",
            logoTextBackground: "#464646",
            logoLightTextBackground: "#E0E6E6",
            descriptionGrey: "#999999",
            silverMetallic: "#AAA9AD",
            mediumMetallicGrey: "#7E838D",
            darkMetallicGrey: "#363739",
            linkGrey: "#7e838d",
            activeBreadcrumbGrey: "#292a2b",
            headerSidebarGrey: "#F5F5F5",
            twitterColor: "#55acee",
            facebookColor: "#3b5998",
            googleColor: "#dd4b39",
            linkedinColor: "#0976b4",
            pinterestColor: "#cc2127",
            youtubeColor: "#e52d27",
            tumblrColor: "#35465c",
            behanceColor: "#1769ff",
            dribbbleColor: "#ea4c89",
            redditColor: "#ff4500",
            instagramColor: "#125688",
            rssColor: "#ee802f",
        },
        background: { paper: "#fff", default: "#fafafa" },
        primary: {
            light: "#88ffff",
            main: "#4dd0e1",
            dark: "#009faf",
            contrastText: "#292a2b",
        },
        secondary: {
            light: "#ffbccf",
            main: "#FE8A9E",
            dark: "#c85a70",
            contrastText: "#292a2b",
        },
        error: {
            light: "rgba(228, 25, 3, 1)",
            main: "rgba(162, 16, 0, 1)",
            dark: "rgba(141, 15, 1, 1)",
            contrastText: "#292a2b",
        },
        warning: {
            light: "#ffb74d",
            main: "#ff9800",
            dark: "#f57c00",
        },
        info: {
            light: "#26c6da",
            main: "#2196f3",
            dark: "#1976d2",
        },
        success: {
            light: "#81c784",
            main: "#4caf50",
            dark: "#388e3c",
        },
        rose: {
            light: "#f8bbd0",
            main: "#e91e63",
            dark: "#d81b60",
        },
        danger: {
            light: "#ebcccc",
            main: "#f44336",
            dark: "#e53935",
        },
        text: {
            primary: "#363739",
            secondary: "#7E838D",
            disabled: "rgba(0, 0, 0, 0.38)",
            hint: "rgba(0, 0, 0, 0.38)",
        },
    },

    typography: {
        fontFamily: ["Lato", "Roboto", "sans-serif"].join(","),
        h1: { fontSize: "2.75rem", lineHeight: "1.15em", fontWeight: 500 },
        h2: { fontSize: "2.25rem", lineHeight: "1.5em", fontWeight: 500 },
        h3: { fontSize: "1.5625rem", lineHeight: "1.4em", fontWeight: 500 },
        h4: { fontSize: "1.255rem", lineHeight: "1.55em", fontWeight: 400 },
        h5: { fontSize: "1.0625rem", lineHeight: "1.45em", fontWeight: 400 },
        h6: {
            fontSize: ".90rem",
            lineHeight: "1.15em",
            fontWeight: 500,
        },
    },
});
