import { useSelector } from "react-redux";
import { useMemo } from "react";
import { cloneDeep } from "lodash";
import { createSelector } from "reselect";
/* eslint-disable */

const selectFirestoreState = (state) => state.firestore;
const selectFirestoreOrdered = (state) => state.firestore.ordered;
const selectFirestoreData = (state) => state.firestore.data;
const selectFirebase = (state) => state.firebase;

const selectAllByDataName = (state, dataName) => dataName;
const selectByDataNameAndId = (state, { dataName: dataName, id: id }) => ({
  dataName,
  id,
});

export const selectByFirestoreState = () =>
  createSelector(
    [selectFirestoreState, selectAllByDataName],
    (firestoreState, firestoreDataName) => {
      if (firestoreDataName) {
        const data = firestoreState[firestoreDataName];

        return data;
      } else {
        const data = firestoreState;

        return data;
      }
    }
  );

export const selectMemoizedByFirestoreState = ({ dataName }) => {
  const selectDataInstance = useMemo(selectByFirestoreState, []);

  const dataInstance = useSelector((state) =>
    selectDataInstance(state, dataName)
  );

  return dataInstance;
};

export const selectByDataName = () =>
  createSelector(
    [selectFirestoreOrdered, selectAllByDataName],
    (firestoreData, dataName) => {
      return firestoreData[dataName];
    }
  );

export const selectByDataNameObject = () =>
    createSelector(
        [selectFirestoreData, selectAllByDataName],
        (firestoreData, dataName) => {
            return firestoreData[dataName];
        }
    );

export const selectByFirebaseDataName = () =>
  createSelector(
    [selectFirebase, selectAllByDataName],
    (firestoreData, dataName) => {
      return firestoreData[dataName];
    }
  );

export const selectMemoizedByDataNameFirestoreOrdered = ({ dataName }) => {
  const selectDataInstance = useMemo(selectByDataName, []);

  const dataInstance = useSelector((state) =>
    selectDataInstance(state, dataName)
  );

  return dataInstance;
};

export const selectMemoizedByDataNameFirestoreData = ({ dataName }) => {
    const selectDataInstance = useMemo(selectByDataNameObject, []);

    const dataInstance = useSelector((state) =>
        selectDataInstance(state, dataName)
    );

    return dataInstance;
};

export const selectMemoizedFirebase = ({ dataName }) => {
  const selectDataInstance = useMemo(selectByFirebaseDataName, []);

  const dataInstance = useSelector((state) =>
    selectDataInstance(state, dataName)
  );

  return dataInstance;
};
