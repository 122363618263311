import React, { Suspense } from "react";
import LinearIndeterminate from "../../components/loading-spinner/linear-indeterminate";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import PrivateRoute from "../../components/private-route";
import SubscribeDrawerContainer from "../../containers/subscribe-drawer-container";
import DisclosurePolicy from "../../components/disclosure-policy";
import TermsOfUse from "../../assets/terms-of-use";
import PrivacyPolicy from "../../components/legal/privacy-policy";
import SubscribeEndpoint from "../../components/subscribe-endpoint";
import UnsubscribeForm from "../../components/app-forms/unsubscribe-form";
import {isAuthAdmin, isAuthUser} from "../../util/helper-utils";
import {selectMemoizedFirebase} from "../../util/firestore/selectors";

export default function BtbApp() {
    const history = useHistory();
    const location = useLocation();
    const profile = selectMemoizedFirebase({ dataName: "profile" });
    const auth = selectMemoizedFirebase({ dataName: "profile" });

    const isAuthorized = React.useMemo(()=>{
        if (profile) {
            return isAuthUser(profile) || isAuthAdmin(profile)
        }
    },[profile]);



    return (
        <div className="btb-app">
            <Suspense fallback={<LinearIndeterminate />}>
                <Switch>
                    <Route exact path="/subscribe">
                        <SubscribeEndpoint auth={auth} profile={profile} isAuthorized={isAuthorized}/>
                    </Route>
                    <Route exact path={"/unsubscribe"} component={UnsubscribeForm}/>
                    <Route exact path="/ftc-disclosure-policy" component={DisclosurePolicy} />
                    <Route exact path="/terms-of-use" component={TermsOfUse} />
                    <Route exact
                        path="/privacy-policy"
                        component={PrivacyPolicy}
                    />
                    <PrivateRoute routeRole={"user"} path={"/"} redirectPath={"/subscribe"}>
                        <SubscribeDrawerContainer  />
                    </PrivateRoute>
                </Switch>
            </Suspense>
        </div>
    );
}
