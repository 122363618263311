import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { isLoaded, isEmpty } from "react-redux-firebase";
import PropTypes from "prop-types";
import { LinearProgress } from '@material-ui/core';
// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated or if auth is not
// yet loaded
export default function PrivateRoute({ children, routeRole, redirectPath,...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  const profile = useSelector((state) => state.firebase.profile);
  const { role } = profile;
  return isLoaded(auth) && isLoaded(profile) ? (
    <Route
      {...rest}
      render={({ location }) =>
        (isLoaded(auth) && !isEmpty(auth) && routeRole === role) ||
        ["socialAdmin", "admin"].includes(role) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectPath,
              state: {
                from: location,
              },
            }}
          />
        )
      }
    />
  ) : (
    <div>
      <LinearProgress />
    </div>
  );
}

PrivateRoute.propTypes = {
  routeRole: PropTypes.oneOf(["admin", "user", "socialAdmin"]).isRequired,
    redirectPath:PropTypes.string,
};
PrivateRoute.defaultProps = {
    redirectPath:"/",
}
