export const AVR_ENDPOINTS = {
  property: {
    url: "property",
    authorization: true,
    method: "POST",
    data_name: "property",
    methods: ["get", "put", "post", "delete"],
  },
  mortgage: {
    url: "mortgage",
    authorization: true,
    method: "POST",
    data_name: "mortgage",
    methods: ["get", "put", "post", "delete"],
  },
  form: {
    url: "load_master_form",
    authorization: false,
    method: "GET",
    data_name: "form",
  },
};

export const API_METHODS = {
  get: "GET",
  post: "POST",
  delete: "DELETE",
  put: "PUT",
  patch: "PATCH",
};
export const AVR_API_ACTIONS = [
  "REQUEST",
  "SUCCESS",
  "COMPLETE",
  "ERROR",
  "UPDATE_REQUEST",
  "UPDATE_SUCCESS",
  "UPDATE_COMPLETE",
  "UPDATE_ERROR",
  "DELETE_REQUEST",
  "DELETE_SUCCESS",
  "DELETE_COMPLETE",
  "DELETE_ERROR",
  "CREATE_REQUEST",
  "CREATE_SUCCESS",
  "CREATE_COMPLETE",
  "CREATE_ERROR",
];

export const API_STATES = {
  INITIAL_STATE: {
    enabled: false,
    isFetching: false,
    isLoaded: false,
    isError: false,
  },

  REQUEST_STATE: {
    enabled: false,
    isFetching: true,
    isLoaded: false,
    isError: false,
  },

  SUCCESS_STATE: {
    enabled: false,
    isFetching: false,
    isLoaded: false,
    isError: false,
  },

  COMPLETE_STATE: {
    enabled: true,
    isFetching: false,
    isLoaded: true,
    isError: false,
  },
  ERROR_STATE: {
    enabled: false,
    isFetching: false,
    isLoaded: false,
    isError: true,
  },
};
