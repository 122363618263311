import React from 'react';
import ReactDOM from 'react-dom';
import BtbBaseApp from "@brooke-vitale/common/src/apps/btb-app";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(<BtbBaseApp />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

