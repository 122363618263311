import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import app from "../data/store/app/app-reducer";

import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { getFirebase } from "react-redux-firebase";
/*
TODO: SANITIZE GEOJSON DATA
action sanitizer/ state sanitizer to implement redux tools - the geospatial data is crashing devtools*/
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyB74-En25s9rUSPbJFrMXdbXnRaQGWROvo",
  authDomain: "brookes-workshop.firebaseapp.com",
  databaseURL: "https://brookes-workshop.firebaseio.com",
  projectId: "brookes-workshop",
  storageBucket: "brookes-workshop.appspot.com",
  messagingSenderId: "33912444151",
  appId: "1:33912444151:web:44f93c665a4fda6e7ceff2",
  measurementId: "G-LZ7KDQHW6K",
};

export const RRF_CONFIG = {
  useFirestoreForStorageMeta: true,
  userProfile: "users",
  useFirestoreForProfile: true,
  enableRedirectHandling: true,
  profileFactory: (user) => {
    let providerData = {};
    if (user.providerData && user.providerData.length) {
      Object.assign(providerData, ...user.providerData);
    }
    const profile = {
      email: user.providerData[0].email,
      role: "user",
      instagram: "",
      facebook: "",
      bookList: true,
      editorList: true,
      promotionList: true,
      created_at: user.metadata.creationTime,
      emailVerified: user.emailVerified,
      ...providerData,
    };
    return profile;
  },
};

const composeEnhancers =
  process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const configureStore = () => {
  let middleware = [thunk, thunk.withExtraArgument(getFirebase)];
  let store;
  if (process.env.NODE_ENV === "development") {
    middleware.push(
      createLogger({
        collapsed: true,
        duration: true,
      })
    );

    store = createStore(
      combineReducers({
        app: app,
        firebase: firebaseReducer,
        firestore: firestoreReducer,
      }),
      composeEnhancers(applyMiddleware(...middleware))
    );

    window.store = store;
    return store;
  } else {
    store = createStore(
      combineReducers({
        app: app,
        firebase: firebaseReducer,
        firestore: firestoreReducer,
      }),
      composeEnhancers(applyMiddleware(...middleware))
    );

    return store;
  }
};

export default configureStore;
