import { rrfProps } from "../../../index";
import { API_STATES, API_METHODS } from "../app-constants";
import "whatwg-fetch";
import { defaultApiArgs } from "./utility-functions";
import {
  SET_SORT_STATE,
  SET_DIALOG_STATE,
  SET_LOGGED_IN_STATUS,
} from "./app-actions";

export const setSortingState = ({
  data_name,
  columnId,
  direction,
  field_name,
}) => (dispatch) => {
  dispatch({
    type: SET_SORT_STATE,
    payload: {
      data_name: data_name,
      columnId: columnId,
      direction: direction,
      field_name: field_name,
    },
  });
};

export const setDialogState = ({ dialogName, meta }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const dialogState = state.app.dialogs[dialogName];
  const newState = dialogState ? !dialogState.status : true;

  dispatch({
    type: SET_DIALOG_STATE,
    payload: {
      dialogName: dialogName,
      open: newState,
      meta: meta,
    },
  });
};

export const setLoggedInStatus = (isLoggedIn) => (dispatch) => {
  dispatch({
    type: SET_LOGGED_IN_STATUS,
    payload: {
      isLoggedIn: isLoggedIn,
    },
  });
};

export const setIsSubscribing = (isSubscribing) => (dispatch) => {
  dispatch({
    type: SET_LOGGED_IN_STATUS,
    payload: {
      isSubscribing: isSubscribing,
    },
  });
};
