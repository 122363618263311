const TermsOfUse = `<!DOCTYPE html>
<html lang="en">

<head>
<meta http-equiv=Content-Type content="text/html; charset=windows-1252">
<meta name=ProgId content=Word.Document>
<meta name=Generator content="Microsoft Word 15">
<meta name=Originator content="Microsoft Word 15">
<style>
<!--
 /* Font Definitions */
 @font-face
\t{font-family:"Cambria Math";
\tpanose-1:2 4 5 3 5 4 6 3 2 4;
\tmso-font-charset:0;
\tmso-generic-font-family:roman;
\tmso-font-pitch:variable;
\tmso-font-signature:3 0 0 0 1 0;}
@font-face
\t{font-family:Calibri;
\tpanose-1:2 15 5 2 2 2 4 3 2 4;
\tmso-font-charset:0;
\tmso-generic-font-family:swiss;
\tmso-font-pitch:variable;
\tmso-font-signature:-536859905 1073786111 1 0 511 0;}
 /* Style Definitions */
 p.MsoNormal, li.MsoNormal, div.MsoNormal
\t{mso-style-unhide:no;
\tmso-style-qformat:yes;
\tmso-style-parent:"";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:8.0pt;
\tmargin-left:0in;
\tline-height:107%;
\tmso-pagination:widow-orphan;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;
\tmso-ascii-font-family:Calibri;
\tmso-ascii-theme-font:minor-latin;
\tmso-fareast-font-family:Calibri;
\tmso-fareast-theme-font:minor-latin;
\tmso-hansi-font-family:Calibri;
\tmso-hansi-theme-font:minor-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:minor-bidi;}
h1
\t{mso-style-name:"Heading 1\\,Subject";
\tmso-style-update:auto;
\tmso-style-priority:9;
\tmso-style-unhide:no;
\tmso-style-qformat:yes;
\tmso-style-link:"Heading 1 Char\\,Subject Char";
\tmso-style-next:Normal;
\tmargin-top:12.0pt;
\tmargin-right:0in;
\tmargin-bottom:0in;
\tmargin-left:0in;
\tline-height:107%;
\tmso-pagination:widow-orphan lines-together;
\tpage-break-after:avoid;
\tmso-outline-level:1;
\tfont-size:14.0pt;
\tmso-bidi-font-size:16.0pt;
\tfont-family:"Calibri Light",sans-serif;
\tmso-ascii-font-family:"Calibri Light";
\tmso-ascii-theme-font:major-latin;
\tmso-fareast-font-family:"Times New Roman";
\tmso-fareast-theme-font:major-fareast;
\tmso-hansi-font-family:"Calibri Light";
\tmso-hansi-theme-font:major-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:major-bidi;
\tcolor:#222A35;
\tmso-themecolor:text2;
\tmso-themeshade:128;
\tmso-font-kerning:0pt;
\tfont-weight:normal;}
p.MsoCommentText, li.MsoCommentText, div.MsoCommentText
\t{mso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-style-link:"Comment Text Char";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:8.0pt;
\tmargin-left:0in;
\tmso-pagination:widow-orphan;
\tfont-size:10.0pt;
\tfont-family:"Calibri",sans-serif;
\tmso-ascii-font-family:Calibri;
\tmso-ascii-theme-font:minor-latin;
\tmso-fareast-font-family:Calibri;
\tmso-fareast-theme-font:minor-latin;
\tmso-hansi-font-family:Calibri;
\tmso-hansi-theme-font:minor-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:minor-bidi;}
span.MsoCommentReference
\t{mso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-ansi-font-size:8.0pt;
\tmso-bidi-font-size:8.0pt;}
a:link, span.MsoHyperlink
\t{mso-style-priority:99;
\tcolor:blue;
\ttext-decoration:underline;
\ttext-underline:single;}
a:visited, span.MsoHyperlinkFollowed
\t{mso-style-noshow:yes;
\tmso-style-priority:99;
\tcolor:#954F72;
\tmso-themecolor:followedhyperlink;
\ttext-decoration:underline;
\ttext-underline:single;}
p
\t{mso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-margin-top-alt:auto;
\tmargin-right:0in;
\tmso-margin-bottom-alt:auto;
\tmargin-left:0in;
\tmso-pagination:widow-orphan;
\tfont-size:12.0pt;
\tfont-family:"Times New Roman",serif;
\tmso-fareast-font-family:"Times New Roman";}
p.MsoCommentSubject, li.MsoCommentSubject, div.MsoCommentSubject
\t{mso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-style-parent:"Comment Text";
\tmso-style-link:"Comment Subject Char";
\tmso-style-next:"Comment Text";
\tmargin-top:0in;
\tmargin-right:0in;
\tmargin-bottom:8.0pt;
\tmargin-left:0in;
\tmso-pagination:widow-orphan;
\tfont-size:10.0pt;
\tfont-family:"Calibri",sans-serif;
\tmso-ascii-font-family:Calibri;
\tmso-ascii-theme-font:minor-latin;
\tmso-fareast-font-family:Calibri;
\tmso-fareast-theme-font:minor-latin;
\tmso-hansi-font-family:Calibri;
\tmso-hansi-theme-font:minor-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:minor-bidi;
\tfont-weight:bold;}
span.Heading1Char
\t{mso-style-name:"Heading 1 Char\\,Subject Char";
\tmso-style-priority:9;
\tmso-style-unhide:no;
\tmso-style-locked:yes;
\tmso-style-link:"Heading 1\\,Subject";
\tmso-ansi-font-size:14.0pt;
\tmso-bidi-font-size:16.0pt;
\tfont-family:"Calibri Light",sans-serif;
\tmso-ascii-font-family:"Calibri Light";
\tmso-ascii-theme-font:major-latin;
\tmso-fareast-font-family:"Times New Roman";
\tmso-fareast-theme-font:major-fareast;
\tmso-hansi-font-family:"Calibri Light";
\tmso-hansi-theme-font:major-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:major-bidi;
\tcolor:#222A35;
\tmso-themecolor:text2;
\tmso-themeshade:128;}
span.CommentTextChar
\t{mso-style-name:"Comment Text Char";
\tmso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-style-unhide:no;
\tmso-style-locked:yes;
\tmso-style-link:"Comment Text";
\tmso-ansi-font-size:10.0pt;
\tmso-bidi-font-size:10.0pt;}
span.CommentSubjectChar
\t{mso-style-name:"Comment Subject Char";
\tmso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-style-unhide:no;
\tmso-style-locked:yes;
\tmso-style-parent:"Comment Text Char";
\tmso-style-link:"Comment Subject";
\tmso-ansi-font-size:10.0pt;
\tmso-bidi-font-size:10.0pt;
\tfont-weight:bold;}
span.SpellE
\t{mso-style-name:"";
\tmso-spl-e:yes;}
span.GramE
\t{mso-style-name:"";
\tmso-gram-e:yes;}
.MsoChpDefault
\t{mso-style-type:export-only;
\tmso-default-props:yes;
\tfont-family:"Calibri",sans-serif;
\tmso-ascii-font-family:Calibri;
\tmso-ascii-theme-font:minor-latin;
\tmso-fareast-font-family:Calibri;
\tmso-fareast-theme-font:minor-latin;
\tmso-hansi-font-family:Calibri;
\tmso-hansi-theme-font:minor-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:minor-bidi;}
.MsoPapDefault
\t{mso-style-type:export-only;
\tmargin-bottom:8.0pt;
\tline-height:107%;}
@page WordSection1
\t{size:8.5in 11.0in;
\tmargin:1.0in 1.0in 1.0in 1.0in;
\tmso-header-margin:.5in;
\tmso-footer-margin:.5in;
\tmso-paper-source:0;}
div.WordSection1
\t{page:WordSection1;}
-->
</style>
<!--[if gte mso 10]>
<style>
 /* Style Definitions */
 table.MsoNormalTable
\t{mso-style-name:"Table Normal";
\tmso-tstyle-rowband-size:0;
\tmso-tstyle-colband-size:0;
\tmso-style-noshow:yes;
\tmso-style-priority:99;
\tmso-style-parent:"";
\tmso-padding-alt:0in 5.4pt 0in 5.4pt;
\tmso-para-margin-top:0in;
\tmso-para-margin-right:0in;
\tmso-para-margin-bottom:8.0pt;
\tmso-para-margin-left:0in;
\tline-height:107%;
\tmso-pagination:widow-orphan;
\tfont-size:11.0pt;
\tfont-family:"Calibri",sans-serif;
\tmso-ascii-font-family:Calibri;
\tmso-ascii-theme-font:minor-latin;
\tmso-hansi-font-family:Calibri;
\tmso-hansi-theme-font:minor-latin;
\tmso-bidi-font-family:"Times New Roman";
\tmso-bidi-theme-font:minor-bidi;}
</style>
<![endif]--><!--[if gte mso 9]><xml>
 <o:shapedefaults v:ext="edit" spidmax="1026"/>
</xml><![endif]--><!--[if gte mso 9]><xml>
 <o:shapelayout v:ext="edit">
  <o:idmap v:ext="edit" data="1"/>
 </o:shapelayout></xml><![endif]-->
</head>

<body lang=EN-US link=blue vlink="#954F72" style='tab-interval:.5in;word-wrap:
break-word'>

<div class=WordSection1>

<p class=MsoNormal style='margin-bottom:7.5pt;line-height:48.0pt;background:
white'><b><span style='font-size:42.0pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman";color:black'>Brooke Vitale Website
Terms of Use<o:p></o:p></span></b></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>Effective Date: April 4, 2021</span></b><span style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><span style='font-size:13.5pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black'>Welcome
to the Brooke Vitale (“<b>Brooke Vitale</b>,” “<b>we</b>,” “<b>us</b>,” or “<b>our</b>”)
website, (</span><span style='color:black;mso-color-alt:windowtext'><a
href="https://brookevitale.com"><span style='font-size:13.5pt;font-family:"Times New Roman",serif;
mso-fareast-font-family:"Times New Roman"'>https://brookevitale.com</span></a></span><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'>) (the &quot;<b>Site</b>&quot;). These Terms of
Use (“<b>Terms</b>”) are a legal agreement between you, a user of the site (“<b>you</b>”
or “<b>your</b>”), and BV BOOKS LLC that governs your use of the Site,
including all related webpages, and downloadable materials, information,
photos, or other documentation (“<b>Content</b>”) that appears on the Site.
Your access to the Site is conditioned upon your acceptance of these Terms and
our Privacy Policy, which is incorporated into these Terms by this reference.
Please read our Terms of Use and Privacy Policy carefully. IF YOU DO NOT AGREE
WITH THESE TERMS, YOU SHOULD NOT ACCESS OR USE THE SITE OR REGISTER TO BECOME A
BROOKE TALKS BOOKS SUBSCRIBER.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>1. Privacy Policy</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
Please review our&nbsp;</span><span style='color:black;mso-color-alt:windowtext'><a
href="https://brookevitale.com/privacy-policy" target="_blank"><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:#CC0000'>Privacy Policy</span></a></span><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'>, which is part of these Terms of Use and
describes how we handle any personally identifying information about Site
users. By accessing, browsing, or using the Site, you expressly consent to the
collection, use, storage, processing, and disclosure of your information,
including personally identifiable information, as described in our Privacy
Policy.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>2. Brooke Talks Books Subscriber</span></b><span style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
You may register for an account on the Site <span class=GramE>in order to</span>
become a<b>&nbsp;“Brooke Talks Books Subscriber.”</b>&nbsp;Becoming a Brooke
Talks Books Subscriber may provide you with access to exclusive Content and the
ability to subscribe to your favorite topics. If you register for a Brooke
Talks Books Subscriber account (<b>“Account”</b>), you must provide us with
your current, complete, and accurate information, including your name, email
address, and a <span class=GramE>user name</span> and password selected by you.
Your <span class=GramE>user name</span> and password may only be used by you
and you are responsible for keeping your user name and password confidential.
You agree that we may attribute all use of your Account to you. BV BOOKS LLC
may permit you to register for and authenticate your Account using a <span
class=GramE>third party</span> social networking service, such as Facebook or
Twitter (<b>“Social Networking Service”</b>). By registering and authenticating
your Account using a Social Networking Service, you agree that BV BOOKS LLC may
access your account information from the Social Networking Service, including
any public profile information associated with your Social Networking Service
account. You are solely responsible for your relationship with the Social
Networking Service, and by offering registration through Social Networking
Services, BV BOOKS LLC is not implying any endorsement or control of any Social
Networking Services. BV BOOKS LLC will never post Content to your Social
Networking Service account. You may deactivate your Account at any time by
selecting “Unsubscribe” at https://brookevitale.com/brooketalksbooks/profile.
Please allow 24 hours for the deactivation to fully process. Deactivating your
Account will unsubscribe you from BV BOOKS LLC emails and will remove your
public profile and Account information from the Site. You may sign back in at
any time <span class=GramE>in order to</span> reactivate your Account and
restore your access to its Content. Please notify us immediately at:&nbsp;<a
href="mailto:contact@webmaster.brookevitale.com">contact@webmaster.brookevitale.com</a>&nbsp;if
you suspect any unauthorized use of your Account or any other breach of
security.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>3. User discussion policy</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
The Site maintains a User Discussion section to provide a forum for sharing
opinions and information regarding the material posted on the Site. All
statements, photos, videos, materials, or other User Content posted by
contributors reflects the views of the individual contributors and does not
reflect the views of BV BOOKS LLC. BV BOOKS LLC takes no responsibility and
assumes no liability for any User Content posted by you or any third party. You
must be a Brooke Talks Books Subscriber to participate in User Discussion
sections of the Site.<br>
<br>
We encourage respectful, constructive dialogue and reserve the right to remove
posts that are offensive, act as advertisements, or attempt to solicit funds.
We also may remove identical or substantially similar comments that are posted
more than once, including those posted for the purpose of monopolizing the
discussion.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>4. Email address submission; Annual Letter</span></b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'><br>
The Site may allow you the option to provide your email address to us <span
class=GramE>in order to</span> receive certain communications from us,
including, but not limited to, occasional updates and our Annual Letter. Any
email communication you receive from us will include an unsubscribe link that
will allow you to opt-out of receiving future emails.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>5. User-generated content</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
The Site may permit you to publicly post content, such as comments, videos,
photos, a profile photo, a biography “blurb,” or other materials under your
Account or as a visitor to the User Discussion sections of the Site (“<b>User
Content</b>”). BV BOOKS LLC does not control or endorse and is not responsible
or liable for User Content. If you post any User Content to the Site, you grant
BV BOOKS LLC and all members of the public who may access publicly-available
User Content on the Site a nonexclusive, royalty-free, perpetual, irrevocable,
and fully sublicensable right to use, reproduce, modify, adapt, publish,
translate, create derivative works from, distribute, and display that User
Content throughout the world in any media, and the right to use and display the
name that you submit in connection with such User Content. By posting User
Content, you represent and warrant that you own or otherwise control <span
class=GramE>all of</span> the rights to the User Content that you post; that
the User Content is accurate; that use of the User Content you supply does not
violate any law or this policy and will not cause injury to any person or
entity. You must not provide any User Content that infringes others’ copyrights
or other intellectual property or privacy rights. You are solely responsible
for all User Content you provide to BV BOOKS LLC or make available through the
Site.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>6. Copyright</span></b><span style='font-size:13.5pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black'><br>
BV BOOKS LLC owns or licenses the copyright in all the materials on the Site,
including text, photographs, graphics, logos, button icons, audio, video and
software, and those rights are protected by United States and international
copyright laws and other intellectual property laws. If you believe that your
work has been copied in a way that constitutes copyright infringement, please
follow our Notice and Procedure for Making Claims of Copyright Infringement,
below.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>7. Permitted distribution</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
You may copy or distribute the materials and Content that appears on the Site
only for non-commercial purposes; specifically, your personal research,
teaching and learning and other similar purposes regarding educational, health
care, environmental, economic, technology, social and political issues. You may
not use the Site materials or Content for any commercial purpose or in any
manner that disparages or discredits any person. All distributed copies must
display the following copyright notice: Copyright BV BOOKS, LLC. Permission to
copy or distribute any materials that appear on the Site that are owned or
copyrighted by others must be obtained from the third party that owns such
content.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>8. Copyright infringement&nbsp;–&nbsp;notification requirements</span></b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'><br>
If you believe that your work has been used on our Site in a way that
constitutes copyright infringement, you should send written notification
thereof, in accordance with the provisions of the Digital Millennium Copyright
Act (the “<b>Notification</b>”), to our Designated Copyright Agent, who can be
reached as follows:<br>
Email:&nbsp;<a href="mailto:legal@contact.brookevitale.com">legal@contact.brookevitale.com</a><br>
<br>
BV BOOKS LLC<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><span style='font-size:13.5pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black'>ATTN:
COPYRIGHT<br>
62 Mary Lane<br>
Riverside, CT 06878<br>
<br>
Pursuant to 17 U.S.C. § 512(c), to be effective, the Notification must include
the following: (<span class=SpellE>i</span>) A physical or electronic signature
of a person authorized to act on behalf of the owner of an exclusive right that
is allegedly infringed. (ii) Identification of the copyrighted work claimed to
have been infringed, or, if multiple copyrighted works at a single online site
are covered by a single notification, a representative list of such works at
that site. (iii) Identification of the material that is claimed to be
infringing or to be the subject of infringing activity and that is to be
removed or access to which is to be disabled, and information reasonably
sufficient to permit us to locate the material. (iv) Information reasonably
sufficient to permit us to contact the complaining party, such as an address, telephone
number, and, if available, an email address. (v) A statement that the
complaining party has a good faith belief that use of the material in the
manner complained of is not authorized by the copyright owner, its agent, or
the law. (vi) A statement that the information in the notification is accurate,
and under penalty of perjury, that the complaining party is authorized to act
on behalf of the owner of an exclusive right that is allegedly infringed. This
process only relates to reporting a claim of copyright infringement. Messages
related to other matters will not receive a response through this process.
Please note that under Section 512(f) of the Digital Millennium Copyright Act,
any person who knowingly and materially misrepresents that material is infringing
may be subject to liability. If you are unsure whether material on the Site is
infringing, we suggest that you contact an attorney prior to sending notice.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>9. Links, frames, and metatags</span></b><span style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
You may link to the home page of the Site <span class=GramE>as long as</span>
you do not do so in a false or misleading manner. You may not frame the content
of the Site. You may not use metatags or any other “hidden text” that
incorporates our Site’s contents without our express written consent.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>10. Links to other websites</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
The Site contains links to other websites that we think may be of interest to
you. We do not endorse or sponsor any <span class=GramE>third party</span>
websites or the information, products, or services contained on any third party
websites and we have no control over third party websites or their content.
Remember that when you link to or share Content on another website, that other
website is governed by its own user agreement and privacy statement, which you
should be sure to read. Access to and use of any <span class=GramE>third party</span>
website is solely at your own risk.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>11. Site availability and support</span></b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'><br>
You may access the Site <span class=GramE>if and when</span> it is available.
We do not guarantee availability of the Site or of materials on the Site. The
Site may occasionally be down for service, upgrades, or for other reasons. To
the maximum extent authorized under applicable law, we reserve the right to
change, remove, delete, restrict, block access to, or stop providing any or <span
class=GramE>all of</span> the Site at any time and without notice. We have no
obligation to provide support in relation to the Site or Content.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>12. Disclaimer of warranties</span></b><span style='font-size:
13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
To the maximum extent permitted by applicable law, the site, including all
text, graphics, logos, audio and video clips, photographs, and other content is
provided “as is,” “with all faults,” and “as available” and the entire risk of
use and performance remains with you. We do not make any representations,
warranties, or conditions, express, implied, or statutory and hereby disclaim <span
class=GramE>any and all</span> warranties with respect to the site. <span
class=GramE>In particular, we</span> make no warranty that the site or content:
(a) will meet your requirements; (b) will be available or provided on an
uninterrupted, timely, secure, or error-free basis; (c) will be accurate,
complete, or reliable, or (d) will be free from viruses, worms, or other harmful
or malicious components. Nor do we warrant that any defects or errors on the
site or content will be corrected. We do not assume any liability relating to
delays or interruptions attributable to third party failures beyond our
control. The site and all content or materials you download or obtain from the
site is accessed at your own risk, and you will be solely responsible for any
damage or loss that results therefrom.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>13. Disclaimer of certain damages</span></b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'><br>
To the maximum extent permitted by applicable law, in no event will we be
liable for any consequential, special, incidental, indirect, or punitive
damages of any kind, whether foreseeable or not, arising out of or in any way
connected with the site or these terms, even if we have been advised of the possibility
of such damages.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>14. Limitation of liability and exclusive remedies</span></b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'><br>
To the maximum extent permitted by applicable law and to the extent they are
not excluded or disclaimed under sections 12 or 13, our maximum, aggregate
liability to you, and your exclusive remedy under these terms for any and all
damages, injuries, and losses arising from any and all claims and causes of
action arising out of, based on, resulting from, or in any way related to these
terms or the site shall be limited to five dollars ($5.00). The existence of
multiple claims or suits under or related to these terms or the site will not
enlarge or extend the limitation of money damages.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>15. Independent remedies</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
The exclusion of damages under Section 13 is independent of your exclusive
remedy in Section 14 and it survives even if the exclusive remedy fails of its
essential purpose or otherwise is deemed unenforceable. Each of the limitations
of liability in Sections 12, 13, and 14 apply without regard to whether loss,
liability, or damage arise from (a) breach of contract, (b) breach of warranty,
(c) fault or tort, including negligence and misrepresentation, (d) strict
liability, or (e) any other cause of action, to the extent the exclusions and
limitations are not prohibited by applicable law.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>16. Notice on potential limits of sections 12, 13, and 14</span></b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'><br>
Some jurisdictions do not allow the exclusion or limitation of damages
(including incidental or consequential), loss, or liability from intentional
acts (including fraud, fraudulent misrepresentation, and failure to disclose
defects), product liability, or for death or personal injury. Nothing in
Sections 12, 13, or 14 will be interpreted as excluding liability which cannot
under applicable law be excluded in those jurisdictions. If you <span
class=GramE>reside, or</span> are otherwise subject to the laws in one of those
jurisdictions, any statutory entitlement available to you will be deemed
limited to the extent (if at all) permissible under that law and, if limitation
is not permitted, the limitations and exclusions in one or more of those
Sections may not apply to you.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>17. Indemnification</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
You hereby agree to defend, indemnify, and hold the BV BOOKS LLC, its
directors, officers, employees, agents, partners, suppliers, and licensors
harmless and will keep them indemnified from any <span class=GramE>third party</span>
claims or demands, including reasonable attorneys’ fees, relating to, arising
from, or allegedly arising from (a) your use of the Site; (b) any violation by
you of these Terms; or (c) your violation of any other party’s rights or
applicable law.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>18. Notices</span></b><span style='font-size:13.5pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black'><br>
We may give you all required notices (including legal process) by any lawful
method, including by posting notices on the Site or by sending notice to any
email address you provide to us. You agree to send notices to us by emailing
them to the following address:&nbsp;<a
href="mailto:legal@brookevitale.com">legal@brookevitale.com</a><o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>19. Changes to these Terms</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
We reserve the right to change these Terms at any time, in our sole discretion,
upon notice to you. We may give notice by posting updated Terms on the Site,
sending you an email, or by any other reasonable means. You should periodically
review these Terms for <span class=GramE>changes</span> and you can review the
most current Terms at any time at:&nbsp;</span><u><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:#CC0000'>https://brookevitale.com/terms-of-use</span></u><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'>. The updated Terms will govern your use of the
Site as of the “Effective Date” date indicated at the top of these Terms. If
you do not agree to the updated Terms, you should stop using the Site. Your use
of the Site after the updated version of these Terms is posted will constitute
your acceptance of the updated Terms.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>20. Termination</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
We reserve the right to terminate the Site and this User Agreement at any time
without notice for any reason, including for your violation of the User
Discussion Policy, these Terms, or our Privacy Policy. The Limitation of
Liability and Governing Law Sections of this User Agreement survive any such
termination. You may permanently delete your Brooke Talks Books Subscriber
Account by going to <a href="https://brookevitale.com/brooketalksbooks/profile">https://brookevitale.com/brooketalksbooks/profile</a>
and clicking unsubscribe or by contacting us at directly:&nbsp;</span><u><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:#CC0000'><a
href="mailto:team@brooketalksbooks.brookevitale.com">team@brooketalksbooks.brookevitale.com</a></span></u><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'>.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>21. Governing law and exclusive jurisdiction and venue</span></b><span
style='font-size:13.5pt;font-family:"Times New Roman",serif;mso-fareast-font-family:
"Times New Roman";color:black'><br>
These Terms and your use of the Site is governed by the laws of the State of Connecticut
applicable to contracts made and performed there, without regard to its
conflicts of law principles. You expressly agree that jurisdiction and venue
for any dispute relating to or arising from these Terms, Content, or the Site
will reside in the State and Federal Courts of King County, Washington.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>22. International use</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
Brooke Talks Books’ servers and operations are located primarily in the United
States and our policies and procedures are based primarily on United States
law. Because of this, the following provisions apply specifically to users
located outside of the United States: (<span class=SpellE>i</span>) you consent
to the transfer, storage, and processing of your information (including
Submissions and Personal Data) to and in the United States and/or other
countries; (ii) if you are using the Services from a country embargoed by the
United States, or are on the United States Treasury Department’s list of
“Specially Designated Nationals,” you are not authorized to access or use the
Services; and (iii) you agree to comply with all local laws, rules, and
regulations including all laws, rules, and regulations in effect in the country
in which you reside and the country from which you access the Services. The
Services are not intended for distribution to or use by any person or entity in
any jurisdiction or country where such distribution or use would be contrary to
law or regulation, or that would subject Brooke Talks Books or its affiliates
to any registration requirement within such jurisdiction or country.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>23. General</span></b><span style='font-size:13.5pt;font-family:
"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";color:black'><br>
If any provision of these Terms is determined by a court of competent
jurisdiction to be illegal, invalid, or unenforceable, the remaining provisions
of these Terms will remain in full force and effect. Section titles are only
for convenience and have no legal or contractual significance. We may assign
these Terms, in whole or in part, at any time, with or without notice to you.
You may not assign, transfer, or sublicense your rights, if any, in the Site.
If, at any time, we fail to respond to a breach of these Terms by you or
others, such failure will not waive our right to act with respect to subsequent
or similar breaches. A waiver will only be binding on us if it is in writing
and signed by us. These Terms (including any incorporated terms or policies)
constitute the entire agreement between you and Brooke Talks Books with respect
to the Site. Both you and BV BOOKS LLC warrant to each other that, in entering
these Terms, neither you nor BV BOOKS LLC have relied on or will have any right
or remedy based upon any statement, representation, warranty, or assurance
other than those expressly stated in these Terms. The preceding sentence will
not limit or exclude any liability that cannot be limited or excluded under
applicable law. No one other than you and BV BOOKS LLC or BV BOOKS LLC’
successors and assigns, will have any right to enforce any of these Terms. You
and BV BOOKS LLC agree that any cause of action arising out of or related to
the site or site materials must commence within one (1) year after the cause of
action accrues; otherwise, such cause of action is permanently barred.<o:p></o:p></span></p>

<p class=MsoNormal style='mso-margin-top-alt:auto;mso-margin-bottom-alt:auto;
line-height:21.0pt;background:white'><b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'>24. Comments and questions</span></b><span style='font-size:13.5pt;
font-family:"Times New Roman",serif;mso-fareast-font-family:"Times New Roman";
color:black'><br>
If you have any questions, <span class=GramE>comments</span> or concerns about
the Site, including materials appearing on the Site, please contact us by going
to the “Send an email” link at the bottom of our home page (under “Contact”)
and using the form located there.<o:p></o:p></span></p>

<p class=MsoNormal><o:p>&nbsp;</o:p></p>

</div>

</body>

</html>
`;

export default TermsOfUse;
