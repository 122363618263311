import React from "react";
import parse from "date-fns/parse";
import isEmpty from "lodash/isEmpty";
import flatten from "lodash/flatten";
import {
  setDialogState,
} from "../data/store/app/app-action-creators";
import { Link as RouterLink, Route } from "react-router-dom";
import { FACEBOOK, INSTAGRAM, YOUTUBE, TWITTER } from "../constants/routes";
import { hexToRgb } from "../assets/giq-jss";
import { addHours, endOfDay } from "date-fns";
import { Link } from "@material-ui/core";
import * as Yup from "yup";


import {
  facebookSvg,
  youTubeSvg,
  twitterSvg,
  instagramSvg,
} from "../assets/svgs/svg";

export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max);
};
export const formYupAssumptions = (yupObject) => {
  return Yup.object().shape(yupObject);
};
export const handleTimeFilter = () => {
  const today = new Date();
  const utc = endOfDay(addHours(today, today.getTimezoneOffset() / 60));
  return utc;
};

export const handleUnsubscribeViaReauthentication =
  ({ profile, firebase, firestore, auth }) =>
  () => {
    let credential = null;
    // const userId = firebase.auth().currentUser.uid;

    let provider;
    if (profile.providerId === "google.com") {
      firebase
        .reauthenticate({ provider: "google", type: "popup" })
        .then((authResult) => {
          // console.log(authResult);
          var user = authResult.user;
          user.delete().then((del) => {});
        })
        .catch((error) => alert(error.message));
    } else {
      firebase
        .reauthenticate({ provider: "facebook", type: "popup" })
        .then((authResult) => {
          var user = authResult.user;
          user.delete().then((del) => {});
        })
        .catch((error) => alert(error.message));
    }
  };

export const getDateFreeKindle = (freeDates) => {
  //free dates is an array of available dates and returns a bool...if true then returns headline
  const d = new Date();
  return freeDates.includes(d.toISOString().split("T")[0]);
};

export const numberPattern = /\d+/g;
export const hexToLinearGradient = ({
  color = [],
  transparency = [],
  degrees = 60,
}) => {
  const mapColors = () => {
    const gradient = color.map((prop, index) => {
      return `${hexToRgbFunc(prop, transparency[index])}`;
    });

    return gradient.join(", ");
  };
  // console.log(mapColors());

  const linearGradient = `linear-gradient(${degrees}deg,${mapColors()})`;
  // console.log(linearGradient);
  return linearGradient;
};

export const hexToRgbFunc = (color, transparency) => {
  // console.log(color);
  if (color.startsWith("#")) {
    return `rgba(${hexToRgb(color)},${transparency})`;
  } else {
    const rgb = color.match(numberPattern);
    // console.log(rgb);
    return `rgba(${rgb[0]},${rgb[1]},${rgb[2]},${transparency})`;
  }
};

export const chargeMommySocialMapping = [
  {
    name: "facebook",
    href: "https://www.facebook.com/chargemommybooks",
    icon: facebookSvg,
    tooltip: "Like our Facebook Page",
    color: "facebook",
  },
  {
    name: "instagram",
    icon: instagramSvg,
    href: "https://www.instagram.com/chargemommybooks/",
    tooltip: "Follow use on Instagram",
    color: "instagram",
  },
];

export const socialMapping = [
  {
    name: "facebook",
    href: FACEBOOK,
    icon: facebookSvg,
    tooltip: "Like my Facebook Page for more great Content!",
    color: "facebook",
  },
  {
    name: "youtube",
    href: YOUTUBE,
    icon: youTubeSvg,
    tooltip: "Subscribe to My YouTube Channel",
    color: "youtube",
  },
  {
    name: "instagram",
    href: INSTAGRAM,
    icon: instagramSvg,
    tooltip: "Follow me on Instagram",
    color: "instagram",
  },
  {
    name: "twitter",
    href: TWITTER,
    icon: twitterSvg,
    tooltip: "Twitter",
    color: "twitter",
  },
];

export const titleCase = (words) =>
  words
    .split("_")
    .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(" ");
export const titleCaseEmpty = (words) =>
  words
    .split(" ")
    .map((w) => w.substring(0, 1).toUpperCase() + w.substring(1))
    .join(" ");

export const getFirebaseToken = (firebase) => {
  return firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(function (idToken) {
      return idToken;
    })
    .catch(function (error) {
      return error;
    });
};

export const emptyStringBool = (value) => {
  return value === "";
};

export const renameKeys = (obj, newKeys, props) => {
  const keyValues = Object.keys(obj).map((key) => {
    const newKey = newKeys[key] || key;
    return {
      [props.capitalize ? titleCase(newKey) : newKey]: obj[key],
    };
  });
  return Object.assign({}, ...keyValues);
};

export const handleIconStyling = (props) => {
  const { mapIcons, icon, height, width, google, colors, idx } = props;
};

export const decodableBookImages = (folderPath, id, name) => {
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${folderPath}/${id}/${name}/${name}.jpg`;
};
export const firebaseBookCoverCopyImages = (folderPath, id, name, size) => {
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${folderPath}/${id}/${name}/${size}-${name}.jpg`;
};

export const firebaseBookDownloadableResources = (folderPath, id, name) => {
  return `${
    process.env.REACT_APP_CLOUD_STORAGE_URL
  }${folderPath}/${id}/${handlePictureFileNameSplit(name)}/${name}`;
};
//
// excel_97_2003:"application/vnd.ms-excel",
//     excel2007:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",

export const fileUploadTypes = {
  excel_97_2003: ".xls",
  excel2007: ".xlsx",
  csv: ".csv",
  text: "text/plain",
  image: "image/*",
  imagePng: [".png", ".PNG", "image/png"],
  imageJpeg: [".jpeg", ".JPEG", ".jpg", ".JPG", "image/jpeg", "image/jpg"],
  imageBitmap: ".bitmap",
  html: "text/html",
  videoAll: ["video/*", ".wav", ".mpg", ".mpeg", ".mp4", ".webm"],
  videoWave: ".wav",
  videoMpg: ".mpg",
  videoMpeg: ".mpeg",
  videoMp4: ".mp4",
  videoWebm: ".webm",
  pdf: [".pdf", "application/pdf"],
};

export const handleAcceptedFileTypes =
  (acceptedFiles, fileUploadTypes) => () => {
    if (!isEmpty(acceptedFiles)) {
      const acceptedFiles = acceptedFiles.map((prop, index) => {
        return fileUploadTypes[prop];
      });

      return flatten(acceptedFiles);
    } else {
      return [];
    }
  };

export const abbrevMonths = [
  "Jan",
  "Feb",
  "Mar",
  "April",
  "May",
  "June",
  "July",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const isAuthAdmin = (profile) => {
  return ["admin", "socialAdmin"].includes(profile.role);
};
export const isAuthUser = (profile) => {
  return ["user"].includes(profile.role);
};
export const cleanWebsite = (website) => {
  return website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
};

export const getCurrentYear = (date) => {
  return date.getFullYear();
};
export const handleThousandsNumberFormatting = (props) => {
  // console.log(props);
  if (props.name.startsWith("year")) {
    return {
      thousandSeparator: true,
    };
  } else if (props.name === "published_date") {
    return {
      thousandSeparator: false,
    };
  } else {
    return {
      thousandSeparator: true,
    };
  }
};
export const fileExportDate = () => {
  const date = new Date();
  return date.toJSON().split("T")[0];
};

export const formattedYearParse = (dateString) => {
  //dateString is a year string

  if (typeof dateString === "string")
    return parse(dateString, "yyyy-MM-dd", new Date());
  else return null;
};

export const firebaseBookCoverFunction = (isbn) => {
  const URIComponent = `books/${isbn}/640wjpeg_thumbnail.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const firebaseBlogPhotoFunction = (blogSlug, fileName) => {
  const URIComponent = `blog/${blogSlug}/${fileName}/1024wjpeg_${fileName}.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const firebaseBlogPostOgTitleFunction = (blogSlug) => {
  const URIComponent = `blog/${blogSlug}/cover_photo/480wjpeg_cover_photo.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const firebasePhotoFunction = (path, id, size = "sm", name) => {
  let pictureSizeMapping = {
    sm: "480wjpeg",
    md: "640wjpeg",
    lg: "1024wjpeg",
  };

  const URIComponent = `${path}/${id}/${pictureSizeMapping[size]}_${
    name.split(".")[0]
  }.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const text_truncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = "...";
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const firebaseBlogOgTitleFunction = () => {
  const URIComponent = `blog/nice-to-meet-you/cover_photo/480wjpeg_cover_photo.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};
export const firebaseBlogPostsCardImageFunction = (blogSlug) => {
  const URIComponent = `blog/${blogSlug}/cover_photo/640wjpeg_cover_photo.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const firebaseVideoBlogPostsCardImageFunction = (id) => {
  const URIComponent = `videoBlogs/${id}/640wjpeg_cover_photo.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const firebaseJsonLdContentUrl = (id, imageSlug, storageCollection) => {
  const URIComponent = `${storageCollection}/${id}/${imageSlug}/1280w-${imageSlug}.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};
export const firebaseJsonLdActivityContentUrl = (
  id,
  imageSlug,
  storageCollection
) => {
  const URIComponent = `${storageCollection}/${id}/${imageSlug}/${imageSlug}.pdf`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};
export const newBlogImageUrl = (id, size, imageSlug, storageCollection) => {
  const URIComponent = `${storageCollection}/${id}/${imageSlug}/${size}w-${imageSlug}.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const firebaseJsonLdThumbUrl = (id, imageSlug, storageCollection) => {
  const URIComponent = `${storageCollection}/${id}/${imageSlug}/thumbw-${imageSlug}.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};
export const checkIfEveryIsEmpty = (data) => {
  //takes an array
  return data.every(checkIfAttributeExistsFirestore);
};

export const handleLookupTableLabels = (
  lookupArray,
  lookupTable,
  sep = " | "
) => {
  let lkpArray = lookupArray;
  if (!Array.isArray(lookupArray)) {
    lkpArray = [lookupArray];
  }

  const tags = lkpArray.map((prop) => {
    const t = lookupTable.options.find((i) => i.value === prop);
    return t;
  });

  return tags.map((prop) => prop.label).join(sep);
};

export const handleLookupTableLabelOnly = (
  lookupArray,
  lookupTable,
  filterKey,
  lookupName,
  sep = " | "
) => {
  const tags = lookupArray.map((prop) => {
    const t = lookupTable.options.find((i) => i[filterKey] === prop);
    return t;
  });

  //only handle lookup tables with a prop name - such as a long author list stored in a map/dict

  return tags.map((prop) => prop[lookupName]).join(sep);
};

export const handlePictureDisabledButton = (props, prop) => {
  const status = props.values.downloadUrls.find(
    (i) => i.name === prop.newFileName
  );
  return !!status;
};

export const firebaseBookReview = (id, name, original = true) => {
  let fileName = `1080squarejpeg_${name}.jpg`;

  if (!original) {
    fileName = `1024wjpeg_${name}.jpg`;
  } else if (name === "cover_photo") {
    fileName = `1024wjpeg_${name}.jpg`;
  }
  const URIComponent = `bookReviews/${id}/${name}/${fileName}`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const firebaseAuthorPicture = (id, name, original = true) => {
  let fileName = `480wjpeg_${name}.jpg`;
  if (!original) {
    fileName = `1024wjpeg_${name}.jpg`;
  }
  const URIComponent = `bookParticipants/${id}/${name}/${fileName}`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};

export const firebaseTestimonialCardImageFunction = (name) => {
  const slugName = name
    .replace(" ", "-")
    .replace(".", "")
    .replace(".", "")
    .toLowerCase();
  const URIComponent = `website/section-testimonials/${slugName}/640wjpeg_${slugName}.jpg`;
  return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
};
export const createTwitterUrl = (username) => {
  return `https://twitter.com/${username}`;
};
export const createInstagramUrl = (username) => {
  return `https://instagram.com/${username}`;
};

export const createFacebookUrl = (username) => {
  return `https://facebook.com/${username}`;
};
export const firebaseSectionCoverImageFunction = (sectionName, width, type) => {
  const slugName = sectionName.replace(" ", "-").toLowerCase();

  if (type === "jpg") {
    const URIComponent = `website/${slugName}/${width}wjpeg_${slugName}.jpg`;
    return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
  } else {
    const URIComponent = `website/${slugName}/${width}wwebp_${slugName}.webp`;
    return `${process.env.REACT_APP_CLOUD_STORAGE_URL}${URIComponent}`;
  }
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export const monthsNum = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

export const timestampToFormattedDate = (
  timestamp,
  dateType = "MMMM-DD-YYYY"
) => {
  // console.log(timestamp);
  //options are MMMM-DD-YYY
  let date = "Invalid Date";
  try {
    date = timestamp.toDate();
    const dayString = date.getDate().toString();
    if (dateType === "MMMM-DD-YYYY") {
      return `${months[date.getMonth()]} ${
        dayString.length === 1 ? `0${dayString},` : `${dayString},`
      } ${date.getFullYear()}`;
    } else if (dateType === "MM-DD-YYYY") {
      return `${monthsNum[date.getMonth()]}-${
        dayString.length === 1 ? `0${dayString}` : `${dayString}`
      }-${date.getFullYear()}`;
    } else return "Invalid Date";
  } catch (e) {
    return "Invalid Date";
  }
};


export const checkIfAttributeExistsFirestore = (prop) => {
  if (prop == null || isEmpty(prop) || prop === "") {
    return false;
  } else {
    return true;
  }
};
export const newTimestampToTableDate = (
  timestamp,
  dateType = "MMMM-DD-YYYY"
) => {
  return timestamp.toDate();
};
export const timestampToTableDate = (timestamp, dateType = "MMMM-DD-YYYY") => {
  //options are MMMM-DD-YYY

  const date = timestamp.toDate();
  const dayString = date.getDate().toString();
  const month = date.getMonth();

  if (dateType === "MMMM-DD-YYYY")
    return `${date.getFullYear()}-${
      month.length === 1 ? `0${month + 1}` : `${month + 1}`
    }-${dayString.length === 1 ? `0${dayString}` : `${dayString}`}`;
  else return null;
};
export const renameFile = (originalFile, newName) => {
  return new File([originalFile], newName, {
    type: originalFile.type,
    lastModified: originalFile.lastModified,
  });
};
export const removeObjectKeys = (obj, removedProps) => {
  //obj is the object and removedProps is an array of keys that should be removed
  return Object.keys(obj).reduce((object, key) => {
    if (!removedProps.includes(key)) {
      object[key] = obj[key];
    }
    return object;
  }, {});
};

export const updateDialogState = (dispatch, dialogName, meta) => {
  return dispatch(setDialogState({ dialogName: dialogName, meta: meta }));
};

export const handlePictureFileNameSplit = (fileName) => {
  return fileName.split(".")[0];
};

export const getUpdatedRoutes = (
  routes,
  pathPrefix,
  WrapperComponent,
  wrapperComponentProps
) => {
  // console.log(routes); put in route object and path prefix to join with path
  return Array.isArray(routes)
    ? routes.map((prop, key) => {
        // console.log(prop);
        // console.log(pathname + "/" + prop.blogSlug);
        const BlogPost = () => {
          if (WrapperComponent) {
            return (
              <WrapperComponent {...wrapperComponentProps}>
                <prop.component {...prop.componentProps} />
              </WrapperComponent>
            );
          } else {
            return <prop.component {...prop.componentProps} />;
          }
        };

        return prop.path ? (
          <Route
            key={key}
            path={pathPrefix ? `/${pathPrefix}/${prop.path}` : `/${prop.path}`}
            component={BlogPost}
            exact={prop.exact ? prop.exact : false}
          />
        ) : null;
      })
    : null;
};

export const reviewPropsAreEqual = (prevId, nextId) => {
  return prevId.id === nextId.id;
};
export const pathnamePropsAreEqual = (prevProps, nextProps) => {
  return prevProps.pathname === nextProps.pathname;
};

export const propsAreEqual = (prevProps, nextProps) => {
  const update =
    prevProps.pathname === nextProps.pathname &&
    prevProps.isAdmin === nextProps.isAdmin
  return update;
};

export const pathnamePropsEqual = (prevProps, nextProps) => {
  const update = prevProps.blogSlug === nextProps.blogSlug;
  console.log(update);
  return update;
};

export const videoBlogPropsAreEqual = (prevId, nextId) => {
  return prevId.videoBlog === prevId.videoBlog;
};

export const EditorMarkdownEqual = (prevProps, nextProps) => {
  console.log(prevProps.showMarkdown === nextProps.showMarkdown);
  return prevProps.showMarkdown === nextProps.showMarkdown;
};


export const createLinks = (routes, prefix = null) =>
  routes.map((prop, key) => {
    if (prop.path && prop.component) {
      return (
        <Link
          key={key}
          color={"textPrimary"}
          underline={"hover"}
          component={RouterLink}
          to={prefix ? `/${prefix}/${prop.path}` : `${prop.path}`}
          variant={"h4"}
        >
          {prop.title}
        </Link>
      );
    } else if (prop.path && !prop.component) {
      return (
        <Link
          key={key}
          color={"textPrimary"}
          underline={"hover"}
          component={RouterLink}
          to={`/${prop.path}`}
          variant={"h4"}
        >
          {prop.title}
        </Link>
      );
    } else {
      return (
        <Link
          key={key}
          color={"textSecondary"}
          underline={"hover"}
          onClick={prop.func}
          variant={"h4"}
        >
          {prop.title}
        </Link>
      );
    }
  });

export const handleRedirectBool = (isRedirected) => {
  return !!isRedirected;
};

export const handleRedirectSlug = (
  isRedirected,
  blogSlug,
  redirectBlogSlug
) => {
  //will return redirected slug....
  if (isRedirected) {
    return redirectBlogSlug;
  } else {
    return blogSlug;
  }
};

