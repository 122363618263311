export const LINKEDIN = "https://www.linkedin.com/company/real-giq";
export const FACEBOOK = "https://www.facebook.com/BrookeVitaleBooks";
export const FACEBOOK_APP_ID = "3720478501318859";
export const FACEBOOK_PAGE = "BrookeVitaleBooks";
export const TWITTER = "https://twitter.com/VitaleBrooke";
export const INSTAGRAM = "https://www.instagram.com/brooke.vitale/";
export const YOUTUBE = "https://www.youtube.com/c/BrookeTalksBooks";
export const PERSONALLINKEDIN =
  "https://www.linkedin.com/in/brooke-vitale-b51ab81/";
export const REEDSY = "https://reedsy.com/brooke-vitale";
export const UPWORK =
  "https://www.upwork.com/o/profiles/users/~01b130d0537bf1287e/";
